<template>
  <div class="container-fluid">
    <d-body-top-bar
      v-if="mode === 'create'"
      :title="$t('views.client.index.new.default')"
    ></d-body-top-bar>
    <div v-if="!validateForm"
      class="container-fluid"
      :class="innerWidth ? '' : 'p-0'"
    >
      <!--    Create Mode       !-->
      <b-card v-if="mode === 'create'">
        <validation-observer
          slim
          ref="customerObserver"
          v-slot="{ invalid }"
        >
          <profile-informations
            :client="client"
            :validation="validation"
          />
          <personal-informations
            :client="client"
            :validation="validation"
          />
          <div class="container-fluid">
            <b-row>
              <b-col
                :align="innerWidth ? 'right' : 'center'"
              >
                <d-button
                  text="general.actions.save"
                  class="d-btn-sm font-text-title btn d-btn-danger font-text-title"
                  :class="innerWidth ? '' : ' custom-customer-button mt-4 mb-3'"
                  @on:button-click="createOrUpdateCustomer"
                />
              </b-col>
            </b-row>
          </div>
        </validation-observer>
      </b-card>
      <!--  CustomUpdate Mode  !-->
      <validation-observer
        v-else
        slim
        ref="customerObserver"
        v-slot="{ invalid }"
      >
        <template v-if="client.id !== null">
          <profile-informations
            :client="client"
            :validation="validation"
          />
          <personal-informations
            :client="client"
            :validation="validation"
            :mode="mode"
          />
          <b-row class="mt-2">
            <b-col
              :align="innerWidth ? 'right' : 'center'"
            >
              <d-button
                text="general.actions.save"
                class="d-btn-sm font-text-title btn d-btn-danger font-text-title"
                :class="innerWidth ? '' : ' custom-customer-button mt-4 mb-3'"
                @on:button-click="createOrUpdateCustomer"
              />
            </b-col>
          </b-row>
        </template>
      </validation-observer>
    </div>

    <div v-if="validateForm" class="bloc-validate">
      <i class="fa fa-check"></i>
      <h5>Bienvenue</h5>
      <span>
        Votre compte client à bien été créé.
      </span>
    </div>

  </div>
</template>

<script>
import Client from "@/classes/doinsport/Client";
import {getClubClient, postClubClient, putClubClient} from "@api/doinsport/services/client/client.api";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  data: () => ({
    validateForm: false,
    client: new Client(),
    addressesIri: null,
    identityPhotoIri: null,
    validation: require('@validation/entities/doinsport/Client.json')
  }),
  props: {
    mode: {
      type: String,
      default: 'create'
    }
  },
  components: {
    ProfileInformations: () => import('@views/client/new/ProfileInformation'),
    PersonalInformations: () => import('@views/client/new/PersonalInformation'),
    Groups: () => import('@views/client/new/Groups'),
    Family: () => import('@views/client/new/Family'),
    CustomerDetails: () => import('@views/client/new/Details'),
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    }
  },
  methods: {
    getCols() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1368) {
        return '12';
      }
      return '6';
    },
    createOrUpdateCustomer() {
      let blobPhoto = null;
      try {
        this.$refs.customerObserver.validate().then((valid) => {
          if (valid) {
            this.client.club = '/clubs/' + this.$store.getters["auth/currentClub"].id;

            delete this.client.documents;
            delete this.client.familyGroup;
            blobPhoto = this.client.identityPhoto;
            delete this.client.identityPhoto;
            if (this.client.phoneNumber.trim().length === 0) {
              this.client.phoneNumber = null;
            }
            if (this.mode === 'create') {
              this.client.birthDate = this.getBirthdayFormat();
              postClubClient(this.client)
                .then((response) => {
                  if (isNotUndefinedAndNotNull(blobPhoto)) {
                    const payload = {entry: 'client', url: response.data['@id'], target: 'postClientIdentityPhoto'};
                    this.$uploadFile(blobPhoto, payload);
                  }

                  this.client = new Client();
                  this.$store.dispatch('validator/set', this.validation);

                  this.validateForm = true;
                  setTimeout(() => {  this.validateForm = false; }, 5000);

                })
              ;
            } else {
              const data = {
                id: this.client.id,
                firstName: this.client.firstName,
                lastName: this.client.lastName,
                phoneNumber: this.client.phoneNumber,
                email: this.client.email,
                affiliated: this.client.affiliated,
                addresses: this.client.addresses,
                birthDate: this.getBirthdayFormat(),
                accessCode: this.client.accessCode,
                licenceNumber: this.client.licenceNumber,
                company: this.client.company,
                gender: this.client.gender,
                description: this.client.description,
                medicalCertificate: this.client.medicalCertificate,
              }
              if (this.addressesIri === null) {
                putClubClient(data)
                  .then((response) => {
                    this.$emit('on:customer:update');
                  })
                ;
              } else {
                data.addresses[0].id = this.addressesIri;

                putClubClient(data)
                  .then((response) => {
                    this.$emit('on:customer:update');
                  })
                ;
              }
            }
          }
        })
      } catch (e) {
      }
    },
    getBirthdayFormat() {
      if (this.client.birthDate && this.client.birthDate !== '') {
        return this.$moment(this.client.birthDate).format('YYYY-MM-DDTHH:mm:ss');
      } else {
        return null;
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('validator/set', this.validation);
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_custom-buttom-mobile.scss";
@import "@lazy/_b-card.scss";

.bloc-validate {
  margin: auto;
  display: flex;
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 400px;
  box-shadow: 0 0 10px 5px rgba(100,100,100, 0.2);
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;
  i {
    margin: auto;
    font-size: 70px;
    color: limegreen;
    margin-bottom: 30px;
  }
  h5 {
    font-weight: 600;
  }
  span {
    font-size: 17px;
  }
}
</style>
